import { memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';

import { ReactComponent as RealEstateDimensionsBlock } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-block.svg';
import { ReactComponent as RealEstateDimensionsPlan } from 'assets/streamline-light/real-estate/maps-dimensions/real-estate-dimensions-plan.svg';
import { ReactComponent as LocationMarker } from 'assets/streamline-light/maps-navigation/pins/pin.svg';
import { ReactComponent as ResizeExpandSides } from 'assets/streamline-light/design/resize/resize-expand-sides.svg';
import { ReactComponent as CommonFileText } from 'assets/streamline-light/files-folders/common-files/common-file-text.svg';
import { ReactComponent as Basket } from 'assets/streamline-light/shopping-ecommerce/baskets/shopping-basket-check.svg';

import Icon from '../../../common/components/ui/icon';
import { useToLocalWithPropertyCurrency } from '../../../common/utils/convertCHF';

import {
  CardContainer,
  ImageWrapper,
  CardImage,
  CardContent,
  CardTitle,
  Row,
  Tag,
  PriceContainer,
  Price,
  Location,
  IconDigit,
  IconLabel,
  SquareMeterPrice,
  LocationIcon,
  NewLabel,
  CardWrapper,
  RequestExposeButton,
} from '../../../landing/components/buyer-lp/suggested-properties/components/suggested-properties-styles';

import SuggestedPropertyActionPanel from './suggested-property-action-panel';
import {
  CountryCodeFromVonPollDbEnum,
  Currency,
  useReadPropertyPurchaseIntentsQuery,
} from '../../../../generated';
import { Tooltip } from '../../../common/components/tooltip';
import { OfferPriceSection } from '../offer-price/offer-price';
import {
  Headline3,
  ParagraphText,
} from 'modules/common/components/ui/typography';
import { ButtonWithTooltip } from 'modules/common/components/ui/buttons/button-with-tooltip';
import { ToolTipBasicContent } from 'modules/common/components/tooltip/TooltipBasicContent';
import { useDispatch } from 'react-redux';
import { setSelectedTab } from 'modules/property/redux/dynamicMapUtilsSlice';
import { siteMap } from 'routes/site-map';
import { useGetCountry } from 'modules/localization/get-country';
import { useParams } from 'react-router-dom';
import FormPurchaseIntend from 'modules/forms/form-purchase-intend/components/containers/form-purchase-intend';
import { FlatButton } from 'modules/common/components/ui/buttons';
import { useAppSelector } from 'modules/common/hooks';

export enum CardTypes {
  SUGGESTED_PROPERTY = 'SuggestedProperty',
  FAVOURITE = 'Favourite',
}

const Title = styled(Headline3)`
  font-size: 16px;
  margin-bottom: 12px;
`;

const Text = styled(ParagraphText)<{ format?: boolean }>`
  ${(props) =>
    props.format &&
    `
       white-space: pre-wrap;
    `}
`;

const IconContainer = styled.div<{
  alignAtEnd?: boolean;
  disabled?: boolean;
}>`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  display: flex;
  flex-direction: column;
  min-width: 0;
  ${({ alignAtEnd }) =>
    alignAtEnd &&
    `
    > div {
      justify-content: flex-end
    }
  `}
`;

const CardFooter = styled.div<{
  isListView?: boolean;
}>`
  /* background: rgba(229, 233, 236, 0.4);
  padding: 12px 12px 24px; */
  padding: ${({ isListView }) => (isListView ? '0' : '12px')};
  flex-grow: 2;
  display: flex;

  width: 100%;

  .button {
    width: 100%;
  }
`;

interface IProps {
  id: string;
  internalId: string;
  title: string;
  price: number;
  numberOfRooms: number;
  livingArea: number;
  landArea: number;
  currency: string;
  imageSrc: string;
  url?: string;
  regionalAddition: string;
  location: string;
  postCode?: string | number;
  isListView?: boolean;
  hasRequestExpose?: boolean;
  isDisabled?: boolean;
  type?: CardTypes;
  isLiked?: boolean;
  isRequestedForExpose?: boolean;
  isPlot?: boolean;
  isNew?: boolean;
  isUnlocked?: boolean;
  countryCode?: CountryCodeFromVonPollDbEnum | null;
  propertyId: number;
  exposeRequestId?: string;
  offeredPrice?: number;
  onClickOpenOverlay?: () => void;
}

const SuggestedProperty = ({
  id,
  internalId,
  title,
  price,
  numberOfRooms,
  livingArea,
  landArea,
  currency,
  imageSrc,
  url,
  regionalAddition,
  location,
  isListView = false,
  hasRequestExpose = false,
  isDisabled = false,
  postCode = '',
  type = CardTypes.SUGGESTED_PROPERTY,
  isLiked = false,
  isRequestedForExpose = false,
  isPlot,
  isNew,
  propertyId,
  isUnlocked = false,
  countryCode,
  exposeRequestId,
  offeredPrice,
  onClickOpenOverlay,
}: IProps): JSX.Element => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { language, getCountry } = useGetCountry();
  const pricePerSqm = Number.parseFloat(
    (price / (livingArea || landArea)).toFixed(2).toString()
  );
  const { searchProfileId } = useParams<{ searchProfileId: string }>();
  const [isOpen, setIsOpen] = useState<boolean>();
  const isLoggedIn = useAppSelector((state) => !!state.auth.user);

  const toLocalWithPropertyCurrency = useToLocalWithPropertyCurrency();

  const onClickPropertyDetails = useCallback(() => {
    if (url) {
      window.open(url, '_blank');
    }
  }, [url]);

  const regionalAdditionString = regionalAddition
    ? `– ${regionalAddition}`
    : '';

  const isGermany = countryCode === CountryCodeFromVonPollDbEnum.Deu;

  const onClickRequestExpose = useCallback(() => {
    if (isUnlocked && onClickOpenOverlay) {
      dispatch(setSelectedTab(2));
      onClickOpenOverlay();
    } else {
      window.open(
        isGermany
          ? `${siteMap.RequestExposePage.pathWithoutParams}/${id}?searchProfileId=${searchProfileId}&propertyId=${propertyId}`
          : `https://www.von-poll.com/${language}/expose/immobilie/${propertyId}`,
        '_blank'
      );
    }
  }, [
    dispatch,
    id,
    isGermany,
    // isDeAtCh,
    isUnlocked,
    language,
    onClickOpenOverlay,
    propertyId,
    searchProfileId,
  ]);

  const { refetch: refetchPropertyPurchaseIntents } =
    useReadPropertyPurchaseIntentsQuery(
      {
        propertyId: internalId || '',
      },
      { skip: !isUnlocked }
    );

  const Footer = () => {
    return (
      <>
        {isLoggedIn && (
          <CardFooter isListView={isListView}>
            {isRequestedForExpose && (
              <>
                <div className="button">
                  <RequestExposeButton
                    label={t('offer-price.purchase-interest')}
                    icon={Basket}
                    iconStyle={{
                      padding: '3px',
                      margin: '0 8px 0 0',
                    }}
                    onClick={() => setIsOpen(true)}
                    style={{ width: '100%' }}
                  />
                </div>
              </>
            )}
            {!isRequestedForExpose && (
              <>
                <div className="button">
                  <RequestExposeButton
                    label={t(
                      isUnlocked
                        ? 'search-profile.suggested-properties.expose-details'
                        : 'search-profile.suggested-properties.request-expose.button'
                    )}
                    icon={CommonFileText}
                    iconStyle={{
                      padding: '3px',
                      margin: '0 8px 0 0',
                    }}
                    onClick={onClickRequestExpose}
                    isUnlocked={isUnlocked}
                    style={{ width: '100%' }}
                  />
                </div>
              </>
            )}
          </CardFooter>
        )}
      </>
    );
  };

  const [squareMetrePrice, squareMetreCurrency] = toLocalWithPropertyCurrency(
    pricePerSqm,
    currency as Currency,
    true
  ) as [string, string];

  return (
    <CardWrapper isListView={isListView}>
      <CardContainer
        isListView={isListView}
        onClick={!hasRequestExpose ? onClickPropertyDetails : undefined}
        style={{ cursor: url && !hasRequestExpose ? 'pointer' : 'default' }}
        isDisabled={isDisabled}
      >
        {isNew && <NewLabel>NEU</NewLabel>}
        <ImageWrapper onClick={onClickRequestExpose} isListView={isListView}>
          <CardImage src={imageSrc} isListView={isListView} />
        </ImageWrapper>
        <CardContent isListView={isListView}>
          <Row style={{ display: 'inline-grid' }}>
            <CardTitle onClick={onClickRequestExpose}>{title}</CardTitle>
          </Row>
          <Row justifyContent={'space-between'}>
            <Row>
              <LocationIcon icon={LocationMarker} color={themeContext.blue} />
              <Tooltip
                id={`location ${postCode} ${location} ${regionalAdditionString}`}
                position="top"
                textAlign="left"
                content={
                  <Location>{`${postCode} ${location} ${regionalAdditionString}`}</Location>
                }
              >
                <Location
                  maxWidth={400}
                >{`${postCode} ${location} ${regionalAdditionString}`}</Location>
              </Tooltip>
            </Row>
            <Tag
              style={{ whiteSpace: 'nowrap' }}
              onClick={onClickPropertyDetails}
            >{`# ${id}`}</Tag>
          </Row>
          <PriceContainer>
            {price === 0 ? (
              <Price style={{ marginRight: 0 }}>
                {t('property.offers.card.price-on-request.label')}
              </Price>
            ) : (
              <>
                {' '}
                <Price>
                  {toLocalWithPropertyCurrency(price, currency as Currency)}
                </Price>
                <SquareMeterPrice>
                  ({squareMetrePrice} {squareMetreCurrency}/m<sup>2</sup>)
                </SquareMeterPrice>
              </>
            )}
          </PriceContainer>
          <Row justifyContent="flex-start" style={{ gap: '24px' }}>
            {!isPlot && (
              <IconContainer>
                <Row margin="0 0 4px 0" alignItems="center">
                  <Icon
                    icon={RealEstateDimensionsPlan}
                    color={themeContext.blue}
                  />
                  <IconDigit>{numberOfRooms}</IconDigit>
                </Row>
                <Row>
                  <IconLabel>{t('property.offers.card.room.label')}</IconLabel>
                </Row>
              </IconContainer>
            )}
            <IconContainer>
              <Row margin="0 0 4px 0" alignItems="center">
                <Icon
                  icon={isPlot ? ResizeExpandSides : RealEstateDimensionsBlock}
                  color={themeContext.blue}
                />
                <IconDigit>
                  {t('approx')} {isPlot ? landArea : livingArea} m<sup>2</sup>
                </IconDigit>
              </Row>
              <Row>
                <IconLabel>
                  {t(
                    isPlot
                      ? 'property-overview.land-area'
                      : 'property-overview.living-area'
                  )}
                </IconLabel>
              </Row>
            </IconContainer>
            {hasRequestExpose && !isListView && (
              <SuggestedPropertyActionPanel
                propertyId={id}
                id={propertyId}
                propertyPrice={price}
                detailsUrl={url ?? ''}
                isRequestedForExpose={isRequestedForExpose}
                isLiked={isLiked}
                isUnsuitable={isDisabled}
                isFavourite={type === CardTypes.FAVOURITE}
                isUnlocked={isUnlocked}
                countryCode={countryCode}
                onClickOpenOverlay={onClickOpenOverlay}
              />
            )}
          </Row>
          {isListView && (
            <Row>
              <Footer />
            </Row>
          )}
        </CardContent>
        {hasRequestExpose && isListView && (
          <Row style={{ padding: '12px' }}>
            <SuggestedPropertyActionPanel
              propertyId={id}
              id={propertyId}
              propertyPrice={price}
              detailsUrl={url ?? ''}
              isRequestedForExpose={isRequestedForExpose}
              isLiked={isLiked}
              isUnsuitable={isDisabled}
              isFavourite={type === CardTypes.FAVOURITE}
              isUnlocked={isUnlocked}
              countryCode={countryCode}
              onClickOpenOverlay={onClickOpenOverlay}
              isListView={isListView}
            />
          </Row>
        )}
      </CardContainer>
      {!isListView && <Footer />}

      {/* <OfferPriceSection
        offeredPrice={offeredPrice}
        price={price ?? 0}
        isListView={isListView}
        internalId={internalId}
        isExposeReleased={isUnlocked}
        exposeRequestId={exposeRequestId}
      /> */}

      <FormPurchaseIntend
        refetch={refetchPropertyPurchaseIntents}
        isOpen={!!isOpen}
        propertyPrice={price}
        propertyId={exposeRequestId}
        onClose={() => setIsOpen(false)}
      />
    </CardWrapper>
  );
};

const SuggestedPropertyCard = memo(SuggestedProperty);

export { SuggestedPropertyCard };
